/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/waypoints@4.0.1/lib/jquery.waypoints.min.js
 * - /npm/waypoints@4.0.1/lib/shortcuts/infinite.min.js
 * - /npm/waypoints@4.0.1/lib/shortcuts/inview.min.js
 * - /npm/waypoints@4.0.1/lib/shortcuts/sticky.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
